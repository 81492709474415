import React, { HtmlHTMLAttributes, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SectionHeading from '../common/SectionHeading'
import gsap from 'gsap'
import SplitType from 'split-type'

const projectStats = [
  {
    icon: '/img/home/projects/nodeops.svg',
    backedBy: 'L1D, Blockchain Founders Fund, Momentum6, Richard Ma (Quantsamp), JD Kanani (Polygon), Rushi (Movement Labs), Dorothy (AltLayer) ++',
    label2: 'Node sales supported for',
    value2: 'XAI, Aethir, Shardeum, Avail ++',
    label3: 'Seed Raised',
    value3: '$5 Million'
  },
  {
    icon: '/img/home/projects/risklayer.svg',
    backedBy: 'Antler, M6, core members of EigenLayer, Ethereum Foundation, Quantstamp, WAGMI Ventures, Hypotenuse, Paul Taylor (ex-Blackrock) ++',
    label2: 'Partners with',
    value2: 'Compound, Arbitrum, Altlayer, EigenLayer, Aptos, Angle Protocol ++',
    label3: 'Protecting AUM of',
    value3: '$10 Billion+'
  },
  {
    icon: '/img/home/projects/emoney.svg',
    backedBy: 'Animoca Brands, GBV, BFF, Momentum6, Kucoin Labs, Morningstar Ventures, Banter Capital ++',
    label2: 'Partners with',
    value2: 'Gala, Xend Finance, zkCross Network, ZetaChain,  ++',
    label3: 'Ongoing Bridge Round Raised',
    value3: '$3.3 Million'
  },
]

const projectStories = [
  {
    icon: '/img/home/projects/nodeops.svg',
    story: `Symbiote played a crucial role in our journey to becoming the fastest-growing infrastructure provider in Web3. Their tailored 12-week program, combined with real-time insights and access to an extensive network of mentors, partners, and investors, was a game-changer. The sprint-based approach provided the strategic framework for our success. Our collaboration also led to key integrations that accelerated our expansion across multiple chains. Symbiote’s support empowered us to transform node operations into a public good for Web3.`
  },
  {
    icon: '/img/home/projects/risklayer.svg',
    story: `Participating in this accelerator has been incredibly valuable for our team. The guidance on token strategy and the introduction to investors have significantly boosted our progress. Over the past three months, we've seen remarkable growth thanks to the team's insights, support, and connections with grant partners. It’s been an amazing journey with tangible results.`
  },
]


export default function Stories() {

  const navigate = useNavigate()

  const statsContainerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {

    statsContainerRef.current!.childNodes.forEach((element: any, i) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: `${i * 100}px 80%`,
          end: `${i * 100}px 80%`,
          scrub: false,
          toggleActions: "play none reverse none",
        },
        x: "20%",
        delay: i * 0.25,
        opacity: 0,
      })
    });

    const storyParaEl = document.querySelectorAll(".story-paragraph")

    storyParaEl.forEach((el: any) => {
      const text = new SplitType(el, { types: 'words' })

      gsap.from(text.words, {
        scrollTrigger: {
          trigger: el,
          start: '-20px 80%',
          end: '-20px 80%',
          markers: false,
          scrub: false,
          toggleActions: "play none reverse none",
        },
        x: 80,
        opacity: 0,
        stagger: 0.01,
      })

    })

  }, [])

  return (
    <div className='text-center pb-8'>
      <div className='w-fit mx-auto'>
        <SectionHeading heading='Success Stories from our Cohorts' />
      </div>
      <div className='container mt-5 px-4 md:px-0'>
        <div ref={statsContainerRef} className='grid gap-14 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
          {
            projectStats.map((project, key) => {
              return <ProjectStatsCard key={key} {...project} />
            })
          }
        </div>
      </div>

      <div className='relative z-10 md:h-[400px] container mt-20 md:mt-10'>
        <div className='md:absolute z-20 left-[3%] top-[10%] w-full md:w-[300px]'>
          <StoriesCard
            icon={projectStories[0].icon}
            story={projectStories[0].story}
          />
        </div>
        <div className='md:absolute z-10 right-[30%] top-[58%] md:w-[300px] mt-10 md:mt-0'>
          <StoriesCard
            icon={projectStories[1].icon}
            story={projectStories[1].story}
          />
        </div>
        <div className='absolute md:relative w-full h-full -z-10 md:z-0 top-0'>
          <div className='absolute -top-10 md:top-0 left-0'>
            <img src="/img/home/double-quotes.png" alt="" className='w-[120px]' />
          </div>
          <div className='absolute right-[10%] -bottom-12 md:bottom-0'>
            <img src="/img/home/double-quotes.png" alt="" className='w-[240px]' />
          </div>
        </div>
      </div>

      <div className='text-center mt-10'>
        <p className='font-light'>Ready to <span className='font-semibold'>100x.</span></p>
      </div>
      <div className='mt-8 text-center'>
        <button
          className='home-apply-btn'
          onClick={() => { navigate(`/contact-form/project-ahead`) }}
        >
          <span>Apply for the program</span>
        </button>
      </div>
    </div>
  )
}

type TProjectStatsCard = {
  icon: string;
  backedBy: string;
  label2: string;
  value2: string;
  label3: string;
  value3: string;
}

function ProjectStatsCard(props: TProjectStatsCard) {
  return <div className='h-[460px]'>
    <div className='project-stats-card h-full relative rounded-2xl text-left px-8 pt-8 pb-12'>
      <div className='absolute -z-10 w-full h-full bottom-[-10px] right-[-10px] rounded-2xl overflow-hidden'>
        <img src="/img/home/stats-bg.jpg" alt="" className='object-cover w-full h-full' />
      </div>
      <div className='relative z-40 h-full flex flex-col justify-between'>
        <div className='flex justify-center'>
          <img src={props.icon} alt="" className='h-[50px] w-[180px]' />
        </div>
        <div className=''>
          <span className='font-bold'>Backed by</span>
          <p className='text-[15px] p-0 m-0'>{props.backedBy}</p>
        </div>
        <div className=''>
          <span className='font-bold'>{props.label2}</span>
          <p className='text-[15px] p-0 m-0'>{props.value2}</p>
        </div>
        <div className=''>
          <span className='font-bold'>{props.label3}</span>
          <p className='animated-card-text'>{props.value3}</p>
        </div>
      </div>
    </div>
  </div>
}


function StoriesCard(props: { icon: string, story: string }) {

  return <div>
    <div className={`backdrop-blur-sm bg-[#26262641] rounded-xl p-2 md:w-[540px]`} style={{ border: '1px solid rgba(255, 255, 255, 0.1)' }}>
      <div className='flex justify-end h-[30px]'>
        <img src={props.icon} alt="" className='h-full' />
      </div>
      <div className='text-start mt-2'>
        <p className='story-paragraph text-sm'>
          {props.story}
        </p>
      </div>
    </div>
  </div>
}